import React, {useEffect, useState} from 'react';
import '../App.css';

function Home() {

  // const apiBaseUrl = process.env.API_BASE_URL;
  const apiBaseUrl = 'https://timelyapi.torokbalazs.hu/';
  const [message, setMessage] = useState('Default');

  useEffect(() => {
    fetch(`${apiBaseUrl}api/hello`)
      .then(response => response.json())
      .then(data => {
        setMessage(data.message);
        // console.log(data);
      })
      .catch(error => console.error('Error:', error));
  }, []);
  
  return (
    <div className="App">
      {message &&
        <h1>
            {message}
        </h1>
      }
    </div>
  )
}

export default Home;
