import React, {useEffect, useState} from 'react';
import '../App.css';
import { useParams } from 'react-router-dom';

function User() {

  // const apiBaseUrl = process.env.API_BASE_URL;
  const apiBaseUrl = 'https://timelyapi.torokbalazs.hu/';

  const { userId } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    console.log(`${apiBaseUrl}${userId}`);
    console.log(`${apiBaseUrl+userId}`);
    fetch(`${apiBaseUrl}${userId}`)
      .then(response => response.json())
      .then(data => setUser(data))
      .catch(error => console.error('Error fetching user data:', error));
  }, [userId]);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
        <h1>
            USER ID: {userId && userId}
        </h1>
    </div>
  )
}

export default User;
